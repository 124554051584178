import axios from 'axios';

const defaultState = {
  gameInfo: null,
  gameInfoMap: {
    levels: {
      // phone: {
      //   name: 'Phone',
      //   levelNext: 'idle',
      //   images: {
      //     sky: 'sky-first',
      //     city: 'city-first',
      //   },
      //   // messages: [],
      // },
      idle: {
        name: 'Начало',
        levelNext: 'c_1',
        images: {
          sky: 'sky-first',
          city: 'city-first',
        },
        messagesIntro: [
          {
            author: 'npc',
            confirmText: 'Далее',
            message: 'Добро пожаловать в Сбер. Хочешь пройти испытание и узнать, сколько бы ты мог зарабатывать у нас?',
          },
          {
            author: 'npc',
            confirmText: 'Поехали',
            message: 'Я буду помогать тебе в прохождении игры!'
          }
        ],
      },
      c_1: {
        name: 'Коллега по команде',
        levelNext: 'c_2',
        images: {
          sky: 'sky-first',
          city: 'city-first',
        },
        messagesIntro: [
          {
            author: 'npc',
            title: 'Вопросы Коллеги по команде',
            message: 'Не думай, что наши менеджеры ничего не понимают в архитектуре. Уверен, что его вопросы заставят тебя поломать голову.',
          }
        ],
        messages: [
          {
            author: 'c_1',
            message: 'Сейчас мы посмотрим, чего ты стоишь, %username%. Пора применить твои навыки на практике',
          },
          {
            author: 'npc',
            message: 'Покажи ему насколько ты крутой спец!',
          },
        ],
        messagesOnFirstHit: [
          {
            author: 'c_1',
            message: 'Похоже ты действительно знаешь, о чем говоришь',
          },
          {
            author: 'npc',
            message: 'Так держать!',
          },
        ],
        messagesOnFirstDamage: [
          {
            author: 'c_1',
            message: 'Ты думал, что сможешь пройти эту игру?',
          },
          {
            author: 'npc',
            message: 'Одна ошибка ничего не значит!',
          },
        ],
        messagesOnWin: [
          {
            author: 'c_1',
            message: 'Впечатляет, %username%, но посмотрим что скажет Архитектор Блока',
          },
          {
            author: 'npc',
            message: 'Молодец!',
          },
        ],
        messagesOnLose: [
          {
            author: 'c_1',
            message: 'Похоже, мы нашли твою точку роста. Впереди еще много вопросов!',
          },
          {
            author: 'npc',
            message: 'Не отчаивайся, %username%…',
          },
        ],
      },
      c_2: {
        name: 'Архитектор Блока',
        levelNext: 'c_3',
        images: {
          sky: 'sky-second',
          city: 'city-second',
        },
        messagesIntro: [
          {
            author: 'npc',
            title: 'Вопросы Архитектора Блока',
            message: 'Похоже, тебя ждёт интервью с нашим Архитектором Блока',
          }
        ],
        messages: [
          {
            author: 'c_2',
            message: 'Еще один джуниор возомнил себя синьором.',
          },
          {
            author: 'npc',
            message: 'Не ошибись, %username%!',
          },
        ],
        messagesOnFirstHit: [
          {
            author: 'c_2',
            message: 'Наконец-то! Кто-то разбирающийся в своем деле',
          },
          {
            author: 'npc',
            message: 'Еще пара верных ответов и ему нечем будет крыть!',
          },
        ],
        messagesOnFirstDamage: [
          {
            author: 'c_2',
            message: 'Твои ошибки делают меня сильнее',
          },
          {
            author: 'npc',
            message: 'Не дай сложным формулировкам запутать себя, %username%',
          },
        ],
        messagesOnWin: [
          {
            author: 'c_2',
            message: 'Столько знаний, но откуда?!',
          },
          {
            author: 'npc',
            message: 'Отличная работа! Ты показал Архитектору Блока, кто здесь настоящая звезда!',
          },
        ],
        messagesOnLose: [
          {
            author: 'c_2',
            message: 'Эммм, а вы точно архитектор?',
          },
          {
            author: 'npc',
            message: 'Не ошиблись, нам назначено!',
          },
        ],
      },
      c_3: {
        name: 'Главный Архитектор',
        levelNext: 'final',
        images: {
          sky: 'sky-third',
          city: 'city-third',
        },
        messagesIntro: [
          {
            author: 'npc',
            title: 'Вопросы Главного Архитектора',
            message: 'Вот ты и встретился с акулой нашего отдела. Но не бойся, он тебя не укусит.',
          }
        ],
        messages: [
          {
            author: 'c_3',
            message: 'Я вижу, что тебе удалось добиться встречи со мной. Ну что, посмотрим, чего ты стоишь?',
          },
          {
            author: 'npc',
            message: 'Произвести хорошее впечатление будет непросто, но я в тебя верю!',
          },
        ],
        messagesOnFirstHit: [
          {
            author: 'c_3',
            message: 'Удивительно! Я думал что на этот никто не ответит',
          },
          {
            author: 'npc',
            message: 'А %username% ответил.',
          },
        ],
        messagesOnFirstDamage: [
          {
            author: 'c_3',
            message: '%username%, ты меня разочаровываешь...',
          },
          {
            author: 'npc',
            message: 'Но у тебя еще есть шанс показать себя!',
          },
        ],
        messagesOnWin: [
          {
            author: 'c_3',
            message: 'Вот это да! Таких крутых спецов как ты еще поискать надо!',
          },
          {
            author: 'npc',
            message: 'Не всем удается впечатлить нашего Главного Архитектора...',
          },
        ],
        messagesOnLose: [
          {
            author: 'c_3',
            message: 'Рад встрече, но все хорошее когда-нибудь кончается.',
          },
          {
            author: 'npc',
            message: 'Может еще не все потеряно...',
          },
        ],
        messageOnWinAfter: [
          {
            author: 'npc',
            message: 'Ты познакомился со всеми руководителями, а это уже немало. Путь был сложным, но ты дошел до конца. Поздравляю, %username%',
          },
        ],
      },
      final: {
        name: 'Финал',
        levelNext: null,
        images: {
          sky: 'sky-third',
          city: 'city-third',
        },
      },
    },
  },
};

const mutations = {
  SET_GAME_INFO: (state, payload) => {
    state.gameInfo = payload;
  },
  SET_HERO_HEALTH: (state, [level, val]) => {
    state.gameInfoMap.levels[level].heroHealth = val;
  },
  SET_BOSS_HEALTH: (state, [level, val]) => {
    state.gameInfoMap.levels[level].bossHealth = val;
  },
  SET_TRIES: (state, [level, val]) => {
    state.gameInfoMap.levels[level].tries = val;
  },
  GAME_INFO_RESET: (state) => {
    state.gameInfo = JSON.parse(JSON.stringify(defaultState.gameInfo))
  },
  GAME_INFO_MAP_RESET: (state) => {
    state.gameInfoMap = JSON.parse(JSON.stringify(defaultState.gameInfoMap))
  },
};

const actions = {
  async fetchGame({ commit }) {
    const { data } = await axios.get('/api/game')
    if (data && data.id && data.current_level) {
      commit('SET_GAME_INFO', data)
    }
    else {
      // await dispatch('startGame')
      const { data: gameNew } = await axios.get('/api/game')
      commit('SET_GAME_INFO', gameNew)
    }
  },
  async startGame () {
    console.log('[vuex-game] startGame start')
    await axios.post('/api/game/new-game')
    console.log('[vuex-game] startGame done')
  },
  async restartGame ({ commit, dispatch }) {
    console.log('[vuex-game] restartGame start')
    await dispatch('finishGame')
    commit('GAME_INFO_RESET')
    commit('GAME_INFO_MAP_RESET')
    await dispatch('fetchGame')
    console.log('[vuex-game] restartGame done')
  },
  async finishGame () {
    console.log('[vuex-game] finishGame done')
    // await axios.post('/api/game/finish')
    await axios.post('/api/game/new-game')
    console.log('[vuex-game] finishGame done')
  },
};

const getters = {};

export default {
  namespaced: true,
  state: defaultState,
  mutations,
  actions,
  getters,
};
