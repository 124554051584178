import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store';

Vue.use(VueRouter);

const LandingPage = () => import(/* webpackChunkName: "landing-page" */ '@/pages/LandingPage');
const GameLayout = () => import(/* webpackChunkName: "game-layout" */ '@/layouts/GameLayout');
const TopPage = () => import(/* webpackChunkName: "top-page" */ '@/pages/TopPage');
const FinalPage = () => import(/* webpackChunkName: "final-page" */ '@/pages/FinalPage');
// const SberTask = () => import(/* webpackChunkName: "sber-task" */ '@/pages/SberTask');
// const SberTaskPreviewPage = () => import(/* webpackChunkName: "sber-task" */ '@/pages/SberTaskPreviewPage');
const SberTaskConf = () => import(/* webpackChunkName: "sber-task" */ '@/pages/SberTaskConf');
const AdminPage = () => import(/* webpackChunkName: "snippet-task" */ '@/pages/AdminPage');
const NotFoundPage = () => import(/* webpackChunkName: "snippet-task" */ '@/pages/NotFoundPage');
const JobPage = () => import('@/pages/JobPage');

// Guards
const needAnon = async (to, from, next) => {
  console.log('[router-needAnon] start')
  if (store.getters.token) {
    console.log('[router-needAnon] Token found!')
    next('/game')
    return
  }
  console.log('[router-needAnon] Token NOT_FOUND!')
  next()
};

const needAuth = async (to, from, next) => {
  if (store.getters.token) {
    console.log('[router-needAuth] Token found!')
    store.commit('user/SET_TOKEN', store.getters.token);
    await store.dispatch('user/fetchUser')
    await store.dispatch('game/fetchGame')
    // await store.dispatch('game/restartGame')
    if (store.state.game.gameInfo.current_level === 'final') {
      next('/final')
    }
    next()
  }
  else {
    console.log('[router-needAuth] Token NOT_FOUND')
    next('/')
  }
};

const needUser = async (to, from, next) => {
  console.log('[router-needUser] start')
  if (store.getters.token) {
    console.log('[router-needUser] Token found!')
    store.commit('user/SET_TOKEN', store.getters.token);
    await store.dispatch('user/fetchUser')
    await store.dispatch('game/fetchGame')
    next()
  }
  else {
    console.log('[router-needUser] Token NOT_FOUND')
    // next('/')
    next()
  }
};

const needGameFinal = async (to, from, next) => {
  console.log('[router-needGameFinal] start')
  if (store.getters.token) {
    console.log('[router-needGameFinal] Token found!')
    store.commit('user/SET_TOKEN', store.getters.token);
    await store.dispatch('user/fetchUser')
    await store.dispatch('game/fetchGame')
    // If not on localhost...?
    if (store.state.game.gameInfo.current_level === 'final') {
      next()
    }
    else {
      if (localStorage.getItem('g_testing') === 'on') next()
      else next('/game')
    }
  }
  else {
    console.log('[router-needGameFinal] Token NOT_FOUND')
    next('/')
  }
};

const publicPage = async (to, from, next) => {
  console.log('[router-publicPage] start')
  const code = to.query.code || localStorage.getItem('g_code')
  // console.log('[router-publicPage] code', code)
  if (code && code === '6085419594') {
    localStorage.setItem('g_code', code)
    next()
  }
  else {
    next('/')
  }
  // if (store.getters.token) {
  //   console.log('[router-publicPage] Token found!')
  //   store.commit('user/SET_TOKEN', store.getters.token);
  //   await store.dispatch('user/fetchUser')
  //   await store.dispatch('game/fetchGame')
  //   console.log('[router-publicPage] start')
  //   next()
  // }
  // next()
};

const routes = [
  {
    path: '/',
    component: LandingPage,
    beforeEnter: needAnon,
  },
  {
    path: '/admin',
    component: AdminPage,
  },
  {
    path: '/game',
    component: GameLayout,
    beforeEnter: needAuth,
  },
  {
    path: '/task',
    component: SberTaskConf,
    beforeEnter: needUser,
  },
  {
    path: '/final',
    name: 'Final',
    component: FinalPage,
    beforeEnter: needGameFinal,
  },
  {
    path: '/top',
    component: TopPage,
    beforeEnter: publicPage,
  },
  {
    path: '/job',
    component: JobPage,
  },
  {
    path: '/404',
    component: NotFoundPage,
  },
  {
    path: '*',
    redirect: '404'
  }
];

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
});

export default router;
