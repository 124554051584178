import Vue from 'vue'

const mutations = { 
  SET_STATE_PROP: (state, [prop, val]) => {
    if (Object.prototype.hasOwnProperty.call(state, prop)) {
      // console.log('SET_STATE_PROP', prop, val)
      Vue.set(state, prop, val)
      // state[prop] = val
    }
  },
}
const actions = {}
const getters = {}

export default {
  namespaced: true,
  state: {
    orientation: 'vertical',
    width: 0,
    widthViewport: 0,
    height: 0,
    heightViewport: 0,
    offsetTopViewport: 0,
    offsetLeftViewport: 0,
    isFocused: false,
    soundOn: false,
  },
  mutations,
  actions,
  getters,
};
