import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';
import getters from './getters';
import user from './modules/user';
import game from './modules/game';
import ui from './modules/ui'

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  reducer: (state) => {
    const saveState = {};
    saveState.user = {
      authToken: state.user.authToken,
    };
    // saveState.soundOn = state.ui.soundOn
    return saveState;
  },
});

const store = new Vuex.Store({
  modules: {
    user,
    game,
    ui,
  },
  getters,
  plugins: [vuexLocal.plugin],
});

export default store;
